// font sizes
@for $i from 1 through 100 {
  .font-size-#{$i} {
    font-size: $i + px !important;
  }
}

// font weight
@for $i from 100 through 900 {
  @if $i % 100==0 {
    .font-weight-#{$i} {
      font-weight: $i !important;
    }
  }
}

//   border radius
@for $i from 1 through 100 {
  .border-radius-#{$i} {
    border-radius: $i + px;
  }
}
//   Line Height
@for $i from 1 through 100 {
  .line-height-#{$i} {
    line-height: $i + px;
  }
}

//   margin
@for $i from 1 through 100 {
  .m-#{$i} {
    margin: $i * 4 + px;
  }
}

//   margin top
@for $i from 1 through 100 {
  .mt-#{$i} {
    margin-top: $i * 4 + px;
  }
}
//   margin bottom
@for $i from 1 through 100 {
  .mb-#{$i} {
    margin-bottom: $i * 4 + px;
  }
}
//   margin left
@for $i from 1 through 100 {
  .ml-#{$i} {
    margin-left: $i * 4 + px;
  }
}
//   margin right
@for $i from 1 through 100 {
  .mr-#{$i} {
    margin-right: $i * 4 + px;
  }
}

//   padding
@for $i from 1 through 100 {
  .p-#{$i} {
    padding: $i * 4 + px;
  }
}

//   padding top
@for $i from 1 through 100 {
  .pt-#{$i} {
    padding-top: $i * 4 + px;
  }
}
//   padding bottom
@for $i from 1 through 100 {
  .pb-#{$i} {
    padding-bottom: $i * 4 + px;
  }
}
//   padding left
@for $i from 1 through 100 {
  .pl-#{$i} {
    padding-left: $i * 4 + px;
  }
}
//   padding right
@for $i from 1 through 100 {
  .pr-#{$i} {
    padding-right: $i * 4 + px;
  }
}
